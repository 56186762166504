var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import AutocompleteBase from '@material-ui/lab/Autocomplete';
import Input from './Input';
import useStyles from './styles/Autocomplete.styles';
import { Avatar } from '../Avatar';
import { Body } from '../Typography';
var Autocomplete = forwardRef(function (_a, ref) {
    var _b = _a.onSelectOption, onSelectOption = _b === void 0 ? function () { } : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.options, options = _d === void 0 ? [{ value: 'Default value' }] : _d, locale = _a.locale, defaultValue = _a.defaultValue, withIcon = _a.withIcon, value = _a.value, _e = _a.clearOnBlur, clearOnBlur = _e === void 0 ? true : _e, _f = _a.allowNonExistedValue, allowNonExistedValue = _f === void 0 ? false : _f, props = __rest(_a, ["onSelectOption", "disabled", "options", "locale", "defaultValue", "withIcon", "value", "clearOnBlur", "allowNonExistedValue"]);
    var classes = useStyles();
    var _g = __read(useState(false), 2), isOpen = _g[0], setIsOpen = _g[1];
    var renderOption = function (option) {
        if (withIcon) {
            var avatarProps = {};
            if (option === null || option === void 0 ? void 0 : option.src)
                avatarProps.src = option === null || option === void 0 ? void 0 : option.src;
            else
                avatarProps.placeHolder = option === null || option === void 0 ? void 0 : option.value;
            return (_jsxs("div", __assign({ className: classes.optionWithIcon }, { children: [_jsxs("div", __assign({ className: classes.optionWithIconFlexDiv }, { children: [_jsx(Avatar, __assign({ size: option.secondary ? 'm' : 's' }, avatarProps, { "aria-label": 'autocomplete-option-avatar' }), void 0), _jsxs("div", __assign({ className: classes.optionWithIconTextContainer }, { children: [_jsx("span", __assign({ "aria-label": 'autocomplete-option-label' }, { children: option.value }), void 0), option.secondary
                                        && (_jsx(Body, __assign({ className: classes.optionWithIconSecondaryText, component: 'span', variant: 'large', weight: 500, "aria-label": 'autocomplete-option-secondary-text' }, { children: option.secondary }), void 0))] }), void 0)] }), void 0), option.tag && option.tag] }), void 0));
        }
        return option.value;
    };
    var renderStartAdornment = function () {
        var option = options.find(function (o) { return o.value === (value === null || value === void 0 ? void 0 : value.value); });
        if (withIcon && option) {
            var avatarProps = {};
            if (option === null || option === void 0 ? void 0 : option.src)
                avatarProps.src = option === null || option === void 0 ? void 0 : option.src;
            else
                avatarProps.placeHolder = option === null || option === void 0 ? void 0 : option.value;
            return (_jsx(Avatar, __assign({ "aria-label": 'autocomplete-option-avatar-image', className: classes.inputStartAdornment, size: 's' }, avatarProps), void 0));
        }
        return null;
    };
    var handleClose = function () { return setIsOpen(false); };
    var handleKeyDown = function (event) {
        var _a;
        var newValue = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.value;
        if (event.key === 'Enter' && newValue && (newValue === null || newValue === void 0 ? void 0 : newValue.trim())) {
            var newOption = {
                value: newValue,
                label: newValue,
            };
            onSelectOption(newOption);
            handleClose();
        }
    };
    return (_jsx(AutocompleteBase, { ref: ref, defaultValue: defaultValue, value: value, options: options, disabled: disabled, noOptionsText: (locale === null || locale === void 0 ? void 0 : locale.noOptionText) || 'No Options', classes: { paper: classes.paper }, clearOnBlur: clearOnBlur, onBlur: handleClose, onChange: function (_, v) {
            onSelectOption(v);
            setIsOpen(false);
        }, getOptionLabel: function (option) { return option.value; }, getOptionSelected: function (option, v) { return (option === null || option === void 0 ? void 0 : option.value) === (v === null || v === void 0 ? void 0 : v.value); }, renderOption: renderOption, onClose: handleClose, onOpen: function () { return setIsOpen(true); }, open: isOpen, renderInput: function (params) {
            var necessaryParams = __assign({ fullWidth: params.fullWidth, size: params.size, id: params.id, inputProps: params.inputProps }, (allowNonExistedValue ? { onKeyDown: handleKeyDown } : {}));
            return (_jsx(Input, __assign({ ref: params.InputProps.ref }, necessaryParams, props, { disabled: disabled, locale: locale, startAdornment: renderStartAdornment() }), void 0));
        } }, void 0));
});
export default Autocomplete;
